import { ReactComponent as PortLogo } from "../assets/ih-logo.svg";
import { ReactComponent as DelphiLogo } from "../assets/delphi-logo.svg";

export default function Projects() {
  return (
    <div className="projects-container">
      <div className="project">
        <div className="project__head">
          <div className="project__head--main">
            <h1>Delphi</h1>
            <DelphiLogo className="about-logo"></DelphiLogo>
          </div>
          <div className="project__head--sub">
            <p>Website for a local Restaurant</p>
          </div>
        </div>
        <div className="project__description">
          <p>Minimal clutter. All info needed. Mobile friendly.</p>
          <p>Fast. Accessible. Reliable.</p>
          <p>
            Built with{" "}
            <a
              href="https://www.gatsbyjs.com/"
              target="_blank"
              rel="noreferrer"
            >
              GatsbyJS
            </a>{" "}
            and hosted on{" "}
            <a href="https://www.netlify.com/" target="_blank" rel="noreferrer">
              Netlify.
            </a>
          </p>
          <p>
            See live{" "}
            <a
              href="https://www.delphi-lahnstein.de/"
              target="_blank"
              rel="noreferrer"
            >
              website.
            </a>
          </p>
        </div>
      </div>

      <div className="project">
        <div className="project__head">
          <div className="project__head--main">
            <h1>My portfolio</h1>
            <PortLogo className="about-logo"></PortLogo>
          </div>
          <div className="project__head--sub">
            <p>Minimal, atmospheric, perfomant.</p>
          </div>
        </div>
        <div className="project__description">
          <p>A simple website to show what I do.</p>
          <p>
            Built with{" "}
            <a href="https://www.reactjs.org" target="_blank" rel="noreferrer">
              React
            </a>
            ,{" "}
            <a href="https://sass-lang.com/" target="_blank" rel="noreferrer">
              Sass
            </a>{" "}
            and{" "}
            <a href="https://www.netlify.com/" target="_blank" rel="noreferrer">
              Netlify
            </a>
            .
          </p>
          <p>
            My{" "}
            <a
              href="https://ihariskos.github.io/old-portfolio/"
              target="_blank"
              rel="noreferrer"
            >
              old portfolio
            </a>{" "}
            is still online.
          </p>
        </div>
      </div>
    </div>
  );
}
