import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/ih-logo.svg";

export default function nav() {
  return (
    <nav className="menu">
      <Link to="/home" aria-label="Home">
        <Logo className="menu__logo" alt="IH Logo Link" />
      </Link>

      <ul className="menu__list">
        <li>
          <Link className="navLink" to="/about">
            └About
          </Link>
        </li>
        <li>
          <Link className="navLink" to="/projects">
            └Projects
          </Link>
        </li>
      </ul>
    </nav>
  );
}
