import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/ih-logo.svg";
// import Firefly from "../components/firefly";

export default function Landing() {
  const navigate = useNavigate();

  function delayPush() {
    navigate("/home");
  }

  return (
    <div onAnimationEnd={delayPush} onClick={delayPush}>
      <div className="landing">
        {/* <Firefly /> */}
        <Logo className="landing__logo" id="logo" />
      </div>
    </div>
  );
}
