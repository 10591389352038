import { Link } from "react-router-dom";

export default function About() {
  return (
    <div className="home-container">
      <div className="home-container__unit">
        <h1>Welcome.</h1>
        <p>
          I'm Ioannis and my focus lies in creating minimal, functional and
          performant web applications. Want to know more{" "}
          <Link className="navLink" to="/about">
            about
          </Link>{" "}
          me?
        </p>
      </div>
    </div>
  );
}
