import { Link } from "react-router-dom";

import { ReactComponent as Email } from "../assets/email.svg";
import { ReactComponent as Github } from "../assets/github.svg";
import { ReactComponent as Twitter } from "../assets/twitter.svg";
import { ReactComponent as Logo } from "../assets/ih-logo.svg";

export default function footer() {
  return (
    <div className="footer">
      <Link to="/" aria-label="Landing">
        <Logo className="footer__logo" alt="IH Logo Link" />
      </Link>
      <div className="footer__tools">
        Made with{" "}
        <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
          React
        </a>{" "}
        and hosted by{" "}
        <a href="https://www.netlify.com/" target="_blank" rel="noreferrer">
          Netlify
        </a>
        .
      </div>
      <div className="footer__contact">
        <a href="mailto:ihariskos@gmail.com" className="footer__contact--link">
          <Email />
        </a>
        <a href="https://github.com/ihariskos" target="_blank" rel="noreferrer">
          <Github />
        </a>
        <a
          href="https://twitter.com/ihariskos"
          target="_blank"
          rel="noreferrer"
        >
          <Twitter />
        </a>
      </div>
    </div>
  );
}
