import { Outlet } from "react-router-dom";
// import Firefly from "../components/firefly";
import Nav from "../components/navigation";
import Footer from "../components/footer";

export default function Layout() {
  return (
    <div className="content">
      <Nav />
      <Outlet />
      {/* <Firefly /> */}
      <Footer />
    </div>
  );
}
