import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./sass/main.scss";

import Home from "../src/pages/home";
import Landing from "../src/pages/landing";
import Layout from "../src/pages/layout";
import Projects from "../src/pages/projects";
import About from "../src/pages/about";

import Firefly from "../src/components/firefly";

function App() {
  return (
    <BrowserRouter>
      <Firefly />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route element={<Layout />}>
          <Route path="home" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="projects" element={<Projects />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
