export default function firefly() {
  const firefly = 15;
  let fireflies = [];

  for (let i = 0; i < firefly; i++) {
    fireflies.push(<div className="firefly"></div>);
  }

  return fireflies;
}
