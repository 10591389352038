export default function About() {
  return (
    <div className="about-container">
      <div className="about-container__unit">
        <h2>Me!?</h2>
        <p>
          I believe that each one of us has a unique viewpoint to express. With
          the help of magical webtechnologies I can make that a reality.
        </p>
        <p>
          I'm a web developer based in The Netherlands that engages in the
          entire lifecycle of a web presence. From the initial inception (idea,
          design, atmosphere) to the final implementation (programming, hosting,
          maintaining).
        </p>
        <p>
          Sustainability is always on my mind. Minimalism in presentation (e.g.
          no pictures if not necessary) can help with data load and helps
          performance (consider people having slow connections and are on mobile
          phones). Hosting that is{" "}
          <a
            href="https://www.greengeeks.com/going-green"
            target="_blank"
            rel="noreferrer"
          >
            carbon-neutral
          </a>{" "}
          (at least) is always a plus and often overlooked.
        </p>
        <p>
          I love using open-source technologies to create singular experiences
          in this ever-changing web of information.
        </p>
      </div>
      <div className="about-container__unit">
        <h2>Tools</h2>
        <p>
          In current times there is virtually an endless amount of technologies
          one can choose from. There is only the questions:{" "}
          <em>
            What do I know how to use and what do I need for this project?
          </em>
        </p>
        <p>
          With that in mind. Here are the technologies I used to create this
          website:{" "}
          <a href="https://www.reactjs.org" target="_blank" rel="noreferrer">
            React
          </a>
          ,{" "}
          <a href="https://sass-lang.com/" target="_blank" rel="noreferrer">
            Sass
          </a>{" "}
          and{" "}
          <a href="https://www.netlify.com/" target="_blank" rel="noreferrer">
            Netlify
          </a>
          . Not only are those one of the fastet technologies available, they
          are also more sustainable (
          <a
            href="https://www.netlify.com/sustainability/"
            target="_blank"
            rel="noreferrer"
          >
            JAMstack
          </a>
          ) than traditional tech stacks.
        </p>
        {/* <p>
          If you'd like to look at the details you can look at the{" "}
          <a
            href="https://github.com/ihariskos/portfolio"
            target="_blank"
            rel="noreferrer"
          >
            source
          </a>{" "}
          of this website .
        </p> */}
      </div>
      <div className="about-container__unit">
        <h2>Setup</h2>
        <ul>
          <li>
            OS: Windows 11 with{" "}
            <a
              href="https://learn.microsoft.com/en-us/windows/wsl/"
              target="_blank"
              rel="noreferrer"
            >
              WSL
            </a>
          </li>
          <li>
            Code:{" "}
            <a
              href="https://code.visualstudio.com/"
              target="_blank"
              rel="noreferrer"
            >
              Visual Studio Code
            </a>
          </li>
          <li>
            Browser:{" "}
            <a
              href="https://www.mozilla.org/en-US/firefox/new/"
              target="_blank"
              rel="noreferrer"
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            Terminal:{" "}
            <a
              href="https://learn.microsoft.com/en-us/powershell/scripting/overview?view=powershell-7.2"
              target="_blank"
              rel="noreferrer"
            >
              Windows Powershell
            </a>
          </li>
          <li>
            Version Control:{" "}
            <a href="https://git-scm.com/" target="_blank" rel="noreferrer">
              Git
            </a>
          </li>
        </ul>
      </div>
      <div className="about-container__unit">
        <h2>Connect</h2>
        <p>
          The best way to get into contact with me is to write me an{" "}
          <a href="mailto:ihariskos@gmail.com">email</a>. Say hello and we can
          start talking.
        </p>
        <p>
          Want to know what is going on with me at the moment? Then take a look
          at the bottom of the page.
        </p>
      </div>
    </div>
  );
}
